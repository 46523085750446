<template>

    <div class="row mx-auto justify-content-center my-5">
        <div class="col-lg-5">

            <b-container fluid @keyup.enter="log_in()">

                <b-row class="my-4">
                    <b-col sm="12" class="text-center">
                        <h1 class="text-primary mt-5"><b-icon-people-fill /> <b>YEDU</b>app!</h1>
                    </b-col>
                </b-row>

                <form v-on:submit.prevent>

                <b-row class="my-2 text-muted" v-if="!auth">
                    <b-col sm="3" class="text-right"><label for="input-default">{{ $t('profile.login') }}</label></b-col>
                    <b-col sm="9" class="text-left"><b-form-input v-model="email" autocomplete="email" required></b-form-input></b-col>
                </b-row>

                <b-row class="my-2 text-muted" v-if="!auth">
                    <b-col sm="3" class="text-right"><label for="input-default">{{ $t('profile.password')}}:</label></b-col>
                    <b-col sm="9" class="text-left"><b-form-input v-model="pass" type="password" autocomplete="password" required></b-form-input></b-col>
                </b-row>

                <b-row class="my-4">
                    <b-col sm="12" class="text-center">
                        <!-- login button -->
                        <b-button v-if="!auth"
                            variant="primary px-3 mx-1 mb-1"  
                            @click="log_in()" 
                            type="submit">
                            {{ $t('profile.log_in') }}
                        </b-button>
                        <!-- logout button -->
                        <b-button v-else
                            @click="logout">
                            {{ $t('profile.logout') }}
                        </b-button>
                        <!-- signup button -->
                        <b-button v-if="!auth"
                            variant="outline-primary px-3 mx-1" 
                            @click="$router.push({name: 'Signup'})">
                            {{ $t('profile.signup') }}
                        </b-button>
                        <!-- reset button -->
                        <b-button v-if="!auth && reset"
                            variant="outline-secondary px-3 mx-1" 
                            @click="resetPass()">
                            {{ $t('profile.reset') }}
                        </b-button>

                    </b-col>
                </b-row>

                </form>
 

            </b-container>
            
        </div>
    </div
    >
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default {
    name: "Login",
    props: {
        verified: { type: Boolean }
    },
    mounted() {
        if(this.verified) this.commit('alert',this.$t('success.verified'));
    },
    data() { return{
        email: '',
        pass: '',
        reset: true,
    }},
    methods: {
        log_in() { this.login(this.email,this.pass); },
        
        /**
         * LOGIN REQUEST    
         */
        async login(email,password) {
        this.commit("setLoading",true);
        await Vue.axios
        .get("/sanctum/csrf-cookie").then(() => {
            axios.post("/api/login", {
                email: email,
                password: password
            }).then(res => { 
                this.commit("setLoading",false);
                this.authUser(res.data);
                this.commit("alert",this.$t("success.logged"));
            })
            .catch(error => {
                this.commit("setLoading",false);
                let errors = error.response ? error.response.data.errors : null;
                if(errors) this.commit("alert",[this.parseErrors(errors),"warning"]);
            });
        });
        },

        /** 
         * RESET
         */
        async resetPass() {
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
            if(!reg.test(this.email)) { this.$store.commit('alert',this.$t('error.email')); return false; }
            await this.axon("post","reset",{email: this.email});
            this.reset = false;
        }
    },

}
</script>